import { Link } from '@remix-run/react';
import range from 'lodash/range';
import pluralize from 'pluralize';
import React, {
  type ReactNode,
  useContext,
  useMemo,
  useRef,
  useState,
} from 'react';
import {
  FormProvider,
  useForm,
  useFormContext,
  useFormState,
  useWatch,
} from 'react-hook-form';
import { proxy, useSnapshot } from 'valtio';

import { CollectiveProviders } from '../components/Collective/Providers';
import { CheckedIcon } from '../components/icons/CheckedIcon';
import { LPLogo } from '../components/icons/LPLogo';
import { XIcon } from '../components/icons/XIcon';
import { Loading } from '../components/Loading';
import { apiService } from '../services/api-service';
import {
  markSnapshottable,
  type ValtioSnapshottable,
  ValtioUtils,
} from '../utils/valtio';

function CreateInquiry() {
  return (
    <CollectiveProviders>
      <Provider>
        <CreateInquiryForm />
      </Provider>
    </CollectiveProviders>
  );
}

export const Component = CreateInquiry;

type CreateInquiryState = {
  step: 'query' | 'questions' | 'complete';
  query: string;
  questions: string[];
  isGenerating: boolean;
  isCreating: boolean;
  inquiryId?: string;
};

class CreateInquiryApi {
  constructor(
    private readonly state: ValtioSnapshottable<CreateInquiryState>
  ) {}
  async generateInquiryPlan(query: string): Promise<void> {
    if (!query) return;

    ValtioUtils.update(this.state, {
      query,
      isGenerating: true,
    });

    try {
      const resp = await apiService.collective.generateLineOfInquiry({ query });
      ValtioUtils.update(this.state, {
        step: 'questions',
        questions: resp.data.questions,
      });
    } finally {
      this.state.isGenerating = false;
    }
  }

  async createInquiry(questions: string[]): Promise<void> {
    const req = {
      query: this.state.query,
      questions: questions.map((question) => ({ text: question })),
    };

    this.state.isCreating = true;
    try {
      const resp = await apiService.collective.createInquiry(req);
      this.state.step = 'complete';
      this.state.inquiryId = resp.data.inquiry.id;
    } finally {
      this.state.isCreating = false;
    }
  }

  async back(): Promise<void> {
    if (this.state.step === 'questions') {
      this.state.step = 'query';
    }
  }
}

type Context = {
  state: ValtioSnapshottable<CreateInquiryState>;
  api: CreateInquiryApi;
};

const context = React.createContext<Context | null>(null);

function Provider(props: { children?: ReactNode }): JSX.Element {
  const state = useRef(
    markSnapshottable(
      proxy({
        step: 'query',
        query: '',
        questions: [],
        isGenerating: false,
        isCreating: false,
      } as CreateInquiryState)
    )
  );

  const api = useMemo(() => new CreateInquiryApi(state.current), []);
  const ctxValue = useMemo(() => ({ state: state.current, api }), [state, api]);
  return <context.Provider value={ctxValue}>{props.children}</context.Provider>;
}

function useCreateInquiryContext(): Context {
  const ctx = useContext(context);
  if (!ctx) throw new Error('CreateInquiryContext is not in the tree!');
  return ctx;
}

function CreateInquiryForm(): JSX.Element {
  const { state } = useCreateInquiryContext();
  const { step, isGenerating, isCreating } = useSnapshot(state);

  return (
    <div className='w-full h-full text-white flex flex-col relative bg-layer-001'>
      {(isGenerating || isCreating) && (
        <div className='absolute inset-0 bg-black bg-opacity-80'>
          <div className='w-full h-full flex items-center justify-center'>
            <Loading
              text={
                isGenerating ? 'Generating a line of inquiry...' : 'Creating...'
              }
            />
          </div>
        </div>
      )}

      <header className='hidden w-full md:flex items-center pl-2.5 pt-2.5 pr-5 justify-between'>
        <LPLogo />
      </header>
      {step === 'query' && <QueryForm />}
      {step === 'questions' && <QuestionsReview />}
      {step === 'complete' && <AllDone />}
    </div>
  );
}

function QueryForm(): JSX.Element {
  const { state, api } = useCreateInquiryContext();
  const { query } = useSnapshot(state);

  const {
    register,
    handleSubmit,
    formState: { isValid },
  } = useForm<{ query: string }>({
    mode: 'onChange',
    defaultValues: {
      query,
    },
  });

  const onNext = handleSubmit(async (data) => {
    await api.generateInquiryPlan(data.query);
  });

  return (
    <div className='flex-1 flex flex-col justify-between'>
      <div className='pt-12 flex flex-col items-center justify-center gap-6'>
        <div className='text-xl md:text-3.5xl font-bold text-center'>
          Create an Inquiry
        </div>
      </div>

      <main className='flex-1 px-5 flex items-center justify-center'>
        <div className='flex-1 flex flex-col justify-center gap-1 lg:max-w-200'>
          <div className='font-bold text-lg'>What do you want to know?</div>
          <div className='text-secondary'>
            Provide details, as if you were explaining it to a new employee.
          </div>
          <textarea
            className='mt-4 flex h-52 py-2 px-2.5 resize-none mb-0 scrollbar field text-base'
            placeholder='I want to know...'
            {...register('query', {
              required: true,
            })}
          />
        </div>
      </main>
      <footer className='px-9.5 pb-12 md:pb-18 flex flex-col items-center gap-5'>
        <button
          type='button'
          disabled={!isValid}
          className={`
            btn w-full h-16 md:max-w-75
            flex items-center justify-center
            bg-[#FF0935] hover:bg-[#FF2D53] transition-colors
            font-bold text-center tracking-wide
          `}
          onClick={onNext}
        >
          Next
        </button>
      </footer>
    </div>
  );
}

type QuestionsFormData = {
  questions: {
    checked: boolean;
    text: string;
  }[];
};

function defaultQuestionFormData(questions: string[]): QuestionsFormData {
  return {
    questions: questions.map((q) => ({
      checked: true,
      text: q,
    })),
  };
}

function NumSelected(): JSX.Element {
  const questions = useWatch<QuestionsFormData, 'questions'>({
    name: 'questions',
  });

  const numSelected = useMemo(() => {
    return questions.filter((q) => q.checked).length;
  }, [questions]);

  return (
    <div className='pt-4 pb-1 text-secondary text-sm'>
      {`${numSelected} ${pluralize('question', numSelected)} selected`}
    </div>
  );
}

function QuestionsNextButton(props: { onCreate: () => void }): JSX.Element {
  const { api } = useCreateInquiryContext();
  const { isValid } = useFormState<QuestionsFormData>();
  const questions = useWatch<QuestionsFormData, 'questions'>({
    name: 'questions',
  });

  const numSelected = useMemo(() => {
    return questions.filter((q) => q.checked).length;
  }, [questions]);

  return (
    <div className='w-full flex flex-col items-center gap-3'>
      <button
        type='button'
        disabled={!isValid || numSelected === 0}
        className={`
          btn w-full h-16 md:max-w-75
          flex items-center justify-center
          bg-[#FF0935] hover:bg-[#FF2D53] transition-colors
          font-bold text-center tracking-wide
        `}
        onClick={props.onCreate}
      >
        Create Inquiry
      </button>
      <button
        type='button'
        className='text-icon-gray'
        onClick={() => api.back()}
      >
        Back
      </button>
    </div>
  );
}

function QuestionItem(props: { index: number }): JSX.Element {
  const { index } = props;
  const [isEditing, setIsEditing] = useState(false);
  const { register, resetField } = useFormContext<QuestionsFormData>();
  const question = useWatch<QuestionsFormData, 'questions.0'>({
    name: `questions.${index}` as 'questions.0',
  });

  const autoResize = (el: HTMLTextAreaElement | null) => {
    if (!el) return;
    el.style.cssText = 'height: auto';
    const height = el.scrollHeight;
    el.style.cssText = `height: ${height}px`;
  };

  const cancelEdit = () => {
    resetField(`questions.${index}.text`);
    setIsEditing(false);
  };

  const saveEdit = () => {
    resetField(`questions.${index}.text`, { defaultValue: question.text });
    setIsEditing(false);
  };

  return (
    <div className='bg-main-layer rounded-md flex items-start p-4 gap-4'>
      <div>
        <input
          type='checkbox'
          className='checkbox-dark'
          disabled={isEditing}
          {...register(`questions.${index}.checked`)}
        />
      </div>

      {isEditing ? (
        <div className='w-full h-full flex items-start gap-2'>
          <textarea
            className='w-full h-auto py-2 px-2.5 resize-none mb-0 scrollbar field text-sm'
            onKeyPress={(e) => {
              e && autoResize(e.currentTarget);
            }}
            {...register(`questions.${index}.text`)}
          />
          <div className='flex flex-col gap-2'>
            <button
              type='button'
              className={`
                flex-none btn rounded-full 
                bg-secondary hover:bg-secondary-hover 
                w-7 h-7 flex items-center justify-center text-green-001 transition-colors
              `}
              onClick={saveEdit}
            >
              <CheckedIcon className='w-5 h-5 fill-current' />
            </button>
            <button
              type='button'
              className={`
                flex-none btn rounded-full 
                bg-secondary hover:bg-secondary-hover 
                w-7 h-7 flex items-center justify-center text-red-001 transition-colors
              `}
              onClick={cancelEdit}
            >
              <XIcon className='w-5 h-5 fill-current' />
            </button>
          </div>
        </div>
      ) : (
        <div
          className={`leading-tight ${
            question.checked ? 'text-white' : 'text-secondary'
          }`}
        >
          {index + 1}. {question.text}{' '}
          <button
            type='button'
            className='btn text-primary'
            disabled={!question.checked}
            onClick={() => setIsEditing(true)}
          >
            Edit
          </button>
        </div>
      )}
    </div>
  );
}

function QuestionsReview(): JSX.Element {
  const { state, api } = useCreateInquiryContext();
  const { questions } = useSnapshot(state) as typeof state;
  const numQuestions = questions.length;

  const form = useForm<QuestionsFormData>({
    mode: 'onChange',
    defaultValues: defaultQuestionFormData(questions),
  });

  const { handleSubmit } = form;

  const questionElements = useMemo(() => {
    return range(numQuestions).map((_, index) => (
      <QuestionItem key={index} index={index} />
    ));
  }, [numQuestions]);

  const onCreate = handleSubmit(async (data) => {
    const selected = data.questions.filter((q) => q.checked).map((q) => q.text);
    await api.createInquiry(selected);
  });

  return (
    <FormProvider<QuestionsFormData> {...form}>
      <div className='flex-1 flex flex-col justify-between min-h-0'>
        <div className='pt-12 flex flex-col items-center justify-center gap-6'>
          <div className='text-xl md:text-3.5xl font-bold text-center'>
            Edit Your Inquiry Plan
          </div>
        </div>

        <main className='my-8 flex-1 px-5 flex items-center justify-center min-h-0'>
          <div className='relative h-full lg:max-w-200 flex flex-col'>
            <div className='font-bold text-lg'>Your Inquiry Plan</div>
            <div className='text-secondary text-sm'>
              Based on your root question, we’ve come up with additional
              questions that you may want your team to cover. Feel free to
              remove or edit any of the questions provided. More questions will
              result in a more comprehensive answer.
            </div>

            <NumSelected />

            <div className='mt-3 space-y-4 pr-2 pb-5 text-sm overflow-auto scrollbar'>
              {questionElements}
            </div>
            <div
              className='absolute bottom-0 left-0 right-0 h-5'
              style={{
                background:
                  'linear-gradient(180deg, rgba(16, 16, 18, 0.00) 0%, #101012 100%)',
              }}
            />
          </div>
        </main>
        <footer className='px-9.5 pb-8 md:pb-18 flex flex-col items-center gap-5'>
          <QuestionsNextButton onCreate={onCreate} />
        </footer>
      </div>
    </FormProvider>
  );
}

function AllDone(): JSX.Element {
  const { state } = useCreateInquiryContext();
  const { inquiryId } = useSnapshot(state);

  return (
    <div className='px-5 flex-1 flex flex-col gap-2 items-center justify-center'>
      <div className='text-xl md:text-3.5xl font-bold text-center tracking-wide'>
        You’re All Set 🎉
      </div>
      <div className='text-base md:text-xl text-secondary text-center'>
        Your inquiry has been created and we’ve notified your team.
      </div>
      <div className='mt-4 w-full flex justify-center'>
        <Link
          to={`/inquiries/${inquiryId}/dashboard`}
          className='w-full md:max-w-75'
        >
          <button
            type='button'
            className={`
              btn w-full h-16 md:max-w-75
              flex items-center justify-center
              bg-[#FF0935] hover:bg-[#FF2D53] transition-colors
              font-bold text-center tracking-wide
            `}
          >
            View Inquiry
          </button>
        </Link>
      </div>
    </div>
  );
}
